import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import {mapGartner, resourcesTrioMapper} from '../common/utils.js';
import "../assets/styles/pages/blank.scss";

const BlankPage = (props) => {
    const {data} = props;
    const {wpPage} = data;
    const {resourcesTrioFooter} = wpPage;

    const coolInsights = resourcesTrioFooter && resourcesTrioFooter.resourcesTrioResources ? resourcesTrioMapper(resourcesTrioFooter.resourcesTrioHeadline, resourcesTrioFooter.resourcesTrioResources) : null;

    const trendemon = props.location.pathname.includes('cato-assets');

    const gartner = mapGartner(wpPage);

    return (
        <Layout
            className="blank-template"
            lang={props.pageContext.lang}
            title={wpPage.title}
            whiteMenu={true}
            yoastData={wpPage.seo || null}
            seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
            gartner={gartner}
            seoPath={props.location.pathname}
            breadcrumbs={[{title: wpPage.title, path: props.location.pathname}]}
            wpid={wpPage.databaseId}
            {...{coolInsights}}
        >
            <div className={`content ${trendemon ? 'trendemon' : ''}`}>
                <div dangerouslySetInnerHTML={{__html: wpPage.content}}/>
                { trendemon &&
                    <div className="ch_row">
                        <div className="trd-ph-embedded" data-group="hub"/>
                    </div>
                }
            </div>
        </Layout>

    )
}

export default BlankPage;

export const pageQuery = graphql`
    query blankPageQuery($id: String!) {
        wpPage(id: {eq: $id}){
            title
            content
            databaseId
            pageSeoTitle {
                pageSeoTitle
            }
            ...YoastData
           ...StickyBanner
           ...ResourcesTrio
           ...GartnerStrip
        }
    }
`